<!--
 * @FilePath: /daren-html-source/src/views/Account/components/tab2.vue
 * @Description: 收款人管理
-->
<template>
  <div class="tab2">
    <fm-button
      icon="icon-add"
      @click="addpayeeBtn"
      type="primary"
      size="medium"
      >{{ $t('IncomeReflect.tabTwo.NewPayee') }}</fm-button
    >
    <div>
      <div class="tableHeader">
        <div
          v-for="(item, index) in tableHeader"
          :key="index"
          :class="`table${index}`"
        >
          {{ item }}
        </div>
      </div>
      <div class="tableContent" v-loading="loadingFlag">
        <div class="empty" v-if="total == 0">
          <img class="emptyImg" :src="$utils.getPng('icon_empty')" alt="" />
          <div class="emptyText">{{ $t('IncomeReflect.tabTwo.NoRecord') }}</div>
        </div>
        <div v-else>
          <div class="item" v-for="item in list" :key="item">
            <div class="table0">{{ item.income_account }}</div>
            <div class="table1">{{ $utils.hidePhone(item.income_name) }}</div>
            <div class="table2">{{ $utils.hideIdcard(item.account) }}</div>
            <div class="table3">¥ {{ item.total }}</div>
            <div class="table4">
              <div class="editbox pointer" @click="showAdd(item)">
                {{ $t('IncomeReflect.tabTwo.Editor') }}
              </div>
              <div class="deleteBox pointer" @click="deletePayee(item)">
                {{ $t('IncomeReflect.tabTwo.Delete') }}
              </div>
            </div>
          </div>
        </div>
        <ChangePhoneDiaVue
          ref="ChangePhoneDia"
          :userInfo="userInfo"
          :showChangePhoneFlag="showChangePhoneFlag"
          @handleClose="showChangePhoneFlag = false"
        ></ChangePhoneDiaVue>
        <AddPayee ref="AddPayee" :isShow="showAddpayFlag" />
        <DeletePayee
          :isShow="showDeletepayFlag"
          @handleClose="showDeletepayFlag = false"
          @confirm="deletePayeeConfirm"
        />
        <div class="pagation">
          <PaginationCom
            ref="paginationCom"
            :total="total"
            @changepage="changepage"
          ></PaginationCom>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PaginationCom from '@/components/Pagination/index.vue'
import AddPayee from '@/components/AddPayee/index.vue'
import DeletePayee from '@/components/DeleteDia/index.vue'
import ChangePhoneDiaVue from '@/components/ChangePhoneDIa/index.vue'
import {
  getPayeeListAPI,
  dletePayeeAPI,
  userInfoAPI,
} from '@/utils/apiList/user'

export default {
  components: {
    PaginationCom,
    AddPayee,
    DeletePayee,
    ChangePhoneDiaVue,
  },
  data() {
    return {
      tableHeader: [
        this.$t('IncomeReflect.tabTwo.UserNickname'),
        this.$t('IncomeReflect.tabTwo.AlipayAccount'),
        this.$t('IncomeReflect.tabTwo.IdNumber'),
        this.$t('IncomeReflect.tabTwo.TextOne'),
        this.$t('IncomeReflect.tabTwo.Controls'),
      ],
      list: [],
      total: 0,
      page: 1,
      showAddpayFlag: false,
      showDeletepayFlag: false,
      deleteId: '',
      loadingFlag: false,
      userInfo: {},
      showChangePhoneFlag: false,
    }
  },
  created() {
    this.getUser()
    this.getPayeeList()
  },
  methods: {
    getUser() {
      userInfoAPI()
        .then(res => {
          if (res.status == 1) {
            this.userInfo = res.data
            this.$store.commit('setAttr', {
              name: 'userInfo',
              val: res.data,
            })
            localStorage.setItem('isDaren', res.data.is_rate_flow)
          }
        })
        .catch(err => {})
    },
    changepage(page) {
      this.page = page
      this.getPayeeList()
    },
    getPayeeList() {
      this.loadingFlag = true
      getPayeeListAPI({ page: this.page, limit: 20 }).then(res => {
        this.loadingFlag = false
        if (res.status == 1) {
          this.list = res.data.accountList.list
          this.total = parseInt(res.data.accountList.total)
        }
      })
    },
    showAdd(item) {
      this.showAddpayFlag = true
      this.$refs.AddPayee.resetTime()
      this.$refs.AddPayee.form.isEdit = '1'
      this.$refs.AddPayee.form.editId = item.id
      this.$refs.AddPayee.form.aliname = item.income_account
      this.$refs.AddPayee.form.aliacc = item.income_name
      this.$refs.AddPayee.form.account = item.account
    },
    addpayeeBtn() {
      if (
        this.userInfo.is_bind_phone == 0 &&
        this.userInfo.is_income_account == 0
      ) {
        this.$refs.ChangePhoneDia.step = 2
        this.$refs.ChangePhoneDia.topTit = '绑定手机号'
        this.showChangePhoneFlag = true
      } else {
        this.showAddpayFlag = true
      }
    },
    deletePayee(item) {
      this.deleteId = item.id
      this.showDeletepayFlag = true
    },
    deletePayeeConfirm() {
      dletePayeeAPI({ id: this.deleteId }).then(res => {
        if (res.status == 1) {
          this.$toast(res.msg, 1)
          this.deleteId = ''
          this.showDeletepayFlag = false
          this.changepage(1)
          this.$refs.paginationCom.setPage(1)
          this.getUser()
        } else {
          this.$toast(res.msg, 2)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tab2 {
  margin-top: 32px;
  .table0 {
    width: 172px;
    @include multiline(1);
    margin-right: 24px;
  }
  .table1 {
    width: 172px;
    text-align: left;
    margin-right: 24px;
  }

  .table2 {
    width: 172px;
    text-align: left;
    margin-right: 24px;
  }
  .table3 {
    width: 120px;
    text-align: right;
    margin-right: 34px;
  }
  .table4 {
    width: 152px;
    margin-left: auto;
    margin-right: 0;
    display: flex;
    justify-content: center;
  }
  .tableHeader {
    width: 100%;
    height: 52px;
    background: #f9f9f9;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    color: #8a8b92;
    display: flex;
    align-items: center;
    padding: 0 24px;
    margin-top: 20px;
  }
  .tableContent {
    display: flex;
    flex-direction: column;
    min-height: 380px;
    .empty {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 104px;
      .emptyImg {
        width: 100px;
        height: 100px;
      }
      .emptyText {
        margin-top: 8px;
        font-size: 16px;
        color: #8a8b92;
      }
    }
    .item {
      font-size: 14px;
      color: #8a8b92;
      display: flex;
      align-items: center;
      padding: 0 24px;
      height: 56px;
      box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    }
    .pagation {
      margin-left: auto;
      margin-right: 0;
      margin-top: 94px;
    }
    .table4 {
      display: flex;
      height: 22px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      .editbox {
        color: #2c2c2c;
      }
      .deleteBox {
        color: #f53f3f;
        margin-left: 14px;
      }
    }
  }
}
</style>
