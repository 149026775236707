<!--
 * @FilePath: /daren-html-source/src/components/OpenSubNoDaren/index.vue
 * @Description: 开通订阅号但是未开通达人前 提示开通达人弹窗
-->
<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    custom-class=""
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left" style="opacity: 0">
          <img :src="$utils.getPng('icon-circle-fill')" />
          <span>提示</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content" style="margin: 24px 0">
        <p class="title">
          <img
            class="iconErr"
            :src="$utils.getPng('icon_err')"
          />您尚未认证飞猫达人
        </p>
        <div class="txt">
          <span>
            抱歉，您的账号尚未认证飞猫达人。为保障平台安全，需认证飞猫达人后才可申请开通订阅号功能。
          </span>
        </div>
      </div>
      <div class="d-footer">
        <fm-button @click="confirm" type="primary" size="small">
          前往认证</fm-button
        >
        <fm-button @click="handleClose" type="info" plain size="small"
          >稍后认证</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    cancelTips: {
      type: String,
      default: "",
    },
    deleteHJ: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #2c2c2c;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt {
  font-size: 14px;
  width: 100%;
  text-align: left;
  
}
.iconErr {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
</style>
