<!--
 * @FilePath: /daren-html-source/src/views/MemberRebate/incomeData.vue
 * @Description: 收益数据
-->
<template>
  <div class="incomeData">
    <div class="incometop">
      <img class="icon_mem" :src="$utils.getPng('icon_mem')" alt="" />
      <div class="info">
        <div class="iTit">{{ $t('MemberRebate.incomeData.MemberRebateProgram') }}</div>
        <div class="iIntr">{{ $t('MemberRebate.incomeData.TextOne') }}</div>
      </div>
      <div class="incomeRight">
        <fm-button
          class="infobtn"
          type="primary"
          plain
          size="mini"
          @click="toplay"
          >{{ $t('MemberRebate.incomeData.Levels') }}</fm-button
        >
        <div class="line"></div>
        <div class="mylevel">
          {{ $t('MemberRebate.incomeData.MyGrade') }}
          <span
            class="levelAct"
            :style="`color:#${$utils.getLevelColor(
              $store.state.userInfo.user_plan.subject
            )}`"
            >{{ $store.state.userInfo.user_plan.subject }}</span
          >
        </div>
      </div>
    </div>
    <MemRbCom :title="$t('MemberRebate.incomeData.MemberRebateIncome') " :memberList="memberList"></MemRbCom>
    <TableVue
      ref="tableVue"
      :tableTitle="tableTitle"
      :tableHeader="[$t('MemberRebate.incomeData.UserNickname') , $t('MemberRebate.incomeData.OpeningTime'), $t('MemberRebate.incomeData.RebateAmount')]"
      :changepage="changepage"
      :tableList="invitedMemberList"
      :total="total"
    ></TableVue>
  </div>
</template>

<script>
import MemRbCom from "@/components/MemRbCom/index.vue";
import TableVue from "@/components/TablePublic/index.vue";
import { invitedMemberListAPI ,invitedGradeAPI} from "@/utils/apiList/offlineRebate";

export default {
  components: {
    MemRbCom,
    TableVue,
  },
  data() {
    return {
      invitedMemberList: [],
      inviteData: [],
      total: 0,
      page: 1,
      memberList:[]
    };
  },
  computed: {
    tableTitle() {
      return `${this.$t('MemberRebate.incomeData.OpenMembership')}（${this.total}）`;
    },
  },
  mounted() {
    this.getinviteNumPeopleList();
    this.invitedGrade()
  },
  methods: {
    getinviteNumPeopleList(page = 1) {
      this.$refs.tableVue.tableLoading = true;
      invitedMemberListAPI({ page: page, limit: 20 }).then((res) => {
        if (res.status == 1) {
          this.$refs.tableVue.tableLoading = false;
          this.total = parseInt(res.data.count);
          this.invitedMemberList = res.data.list;
        }
      });
    },
    invitedGrade() {
      //获取今日昨日
      invitedGradeAPI().then((res) => {
        if (res.status == 1) {
          this.memberList = res.data.coupon;
        }
      });
    },
    changepage(page) {
      this.page = page;
      this.getinvitedMemberList(page);
    },
    toplay() {
      this.$router.push({ path: "/help", query: { id: 1 } });
    },
  },
};
</script>

<style lang="scss" scoped>
.incomeData {
  .incometop {
    width: 100%;
    box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    display: flex;
    align-items: center;
    padding-bottom: 32px;
    .icon_mem {
      width: 64px;
      height: 64px;
    }
    .info {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      .iTit {
        height: 28px;
        font-weight: 600;
        font-size: 20px;
        color: #2c2c2c;
        line-height: 28px;
        margin-bottom: 8px;
      }
      .iIntr {
        height: 22px;
        font-weight: 400;
        font-size: 14px;
        color: #8a8b92;
        line-height: 22px;
      }
    }
    .incomeRight {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 0;
      .infobtn {
        width: 72px;
        height: 28px;
        border-radius: 18px;
        font-weight: 500;
        font-size: 12px;
      }

      .line {
        width: 1px;
        height: 16px;
        background: #e5e5e5;
        margin: 0 12px;
      }
      .mylevel {
        height: 20px;
        font-weight: 400;
        font-size: 12px;
        color: #8a8b92;
        line-height: 20px;
        .levelAct {
          color: #f28c2a;
        }
      }
    }
  }
}
</style>
