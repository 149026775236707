<!--
 * @FilePath: /daren-html-source/src/views/OfflineRebate/InvitationLink.vue
 * @Description: 邀请注册链接
-->
<template>
  <div class="invitation-link">
    <div class="title" style="margin-top: 0">
      {{$t('OfflineRebate.InvitationLink.Text')}}
    </div>
    <div class="explain">
      <div class="card" style="padding: 16px 20px;min-height: 124px;">
        <p style="color: #2C2C2C;margin-bottom: 4px;"> {{$t('OfflineRebate.InvitationLink.TextOne')}}</p>
        <p>{{$t('OfflineRebate.InvitationLink.TextTwo')}}</p>
        <p>{{$t('OfflineRebate.InvitationLink.TextThree')}}</p>
        <p>{{$t('OfflineRebate.InvitationLink.TextFour')}}</p>
      </div>
      <div class="carousel" :style="{height: lang==='zh'?'124px':'212px'}">
        <transition-group name="list" tag="ul" mode="out-in">
          <li class="item" v-for="item in list" v-bind:key="item" style="white-space: pre;">
            {{ item }}
          </li>
        </transition-group>
        <div class="mask" :style="{background: `linear-gradient(rgb(255,255,255), rgba(255,255,255, 0) ${lang==='zh'?'30%':'20%'},rgba(255,255,255, 0) ${lang==='zh'?'70%':'80%'},rgb(255,255,255))`}"></div>
      </div>
    </div>
    <div class="title">
      {{$t('OfflineRebate.InvitationLink.InviteNow')}}
    </div>
    <div class="inviteLink">
      <div class="card" style="padding: 13px 20px">
        {{ inviteData.link }}
      </div>
      <div class="copyBtn pointer" @click="copyBtn(inviteData.link)">
        <svg-btn icon-class="icon-copy" />
        <span style="margin-left: 8px;">{{$t('OfflineRebate.InvitationLink.CopyInvitationLink')}}</span>
      </div>
    </div>
    <div class="title">
      {{$t('OfflineRebate.InvitationLink.DownLineIncome')}}
    </div>
    <div class="box">
      <div class="day">
        <div class="label">
          <svg-btn icon-class="icon4" />
          <span style="margin-left: 4px;">{{$t('OfflineRebate.InvitationLink.TodayEarnings')}}</span>
        </div>
        <div class="income">
          <span v-if="lang!=='zh'" class="unit">￥</span>
          <span class="value">{{amount.today_coupon}}</span>
          <span v-if="lang==='zh'" class="unit">元</span>
        </div>
        <div class="last">
          <span>{{$t('OfflineRebate.InvitationLink.Yesterday')}}</span>
          <span style="margin-left: 8px;">{{lang==='zh'?`${amount.yesterday_coupon}元`:`￥${amount.yesterday_coupon}`}}</span>
        </div>
        <img :src="$utils.getPng('back-1')" height="144" alt="">
      </div>
      <div class="month">
        <div class="label">
          <svg-btn icon-class="icon4" />
          <span style="margin-left: 4px;">{{$t('OfflineRebate.InvitationLink.CurrentEarnings')}}</span>
        </div>
        <div class="income">
          <span v-if="lang!=='zh'" class="unit">￥</span>
          <span class="value">{{amount.month_coupon}}</span>
          <span v-if="lang==='zh'" class="unit">元</span>
        </div>
        <div style="display: flex;align-items: center;cursor: pointer;position: absolute;right: 24px;z-index: 1;top: 26px;font-weight: 400;" @click="more">
          <span style="font-size: 13px;margin-right: 2px;">{{$t('OfflineRebate.InvitationLink.SeeMore')}}</span>
          <svg-btn icon-class="icon-right" style="width: 12px;height: 12px;" />
        </div>
        <img :src="$utils.getPng('back-2')" height="144" alt="">
      </div>
    </div>
    <TableVue
      ref="tableVue"
      :tableTitle="$t('OfflineRebate.InvitationLink.InvitationRecord')"
      :changePageF="changePageF"
      :tableList="inviteNumPeopleList"
      :total="total"
    ></TableVue>
  </div>
</template>

<script>
import TableVue from "./Table.vue";
import name from './name'
import {
  invitedDiscountCodeAPI,
  inviteNumPeopleAPI,
} from "@/utils/apiList/offlineRebate";

export default {
  components: {
    TableVue,
  },
  data() {
    return {
      inviteData: [],
      inviteNumPeopleList: [],
      total: 0,
      page: 1,
      am: false,
      list: [],
      amount: {
        yesterday_coupon: ''
      },
      lang: ''
    };
  },
  created() {
    this.invite();
    for(let i=0;i<8;i++) {
      this.list.push(this.getText())
    }
  },
  mounted() {
    this.lang = localStorage.getItem('lang')??'zh'
    setInterval(() => {
        this.list.shift()
        this.list.push(this.getText())
      }, 3000)
    this.getinviteNumPeopleList();
  },
  methods: {
    getText() {
      const nickname = name[Math.floor(Math.random()*name.length)]
      const pad = ''.padEnd(nickname.length-2>6?6:nickname.length-2, '*')
      return `${nickname.at(1)}${pad}${nickname.at(-1)}  获得${(Math.random()*(899-148)+148).toFixed(2)}元下线返利`
    },
    invite() {
      invitedDiscountCodeAPI().then((res) => {
        if (res.status == 1) {
          this.inviteData = res.data.invited;
        }
      });
    },
    more() {
      this.$emit('change')
    },
    //生成支付二维码
    copyBtn(url) {
      this.$copyText(url).then(
        (e) => {
          this.$toast("复制成功", 1);
        },
        function (e) {
          this.$toast("复制失败", 2);
        }
      );
    },
    
    saveFile() {
      var parent = document.getElementById("qrcode");
      var child = parent.getElementsByTagName("img");
      var src = child[0].currentSrc;
      var $a = document.createElement("a");
      $a.setAttribute("href", src);
      $a.setAttribute("download", "下载.png"); // Set the filename with extension
      var evObj = document.createEvent("MouseEvents");
      evObj.initMouseEvent(
        "click",
        true,
        true,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        true,
        false,
        0,
        null
      );
      $a.dispatchEvent(evObj);
    },
    getinviteNumPeopleList(page = 1) {
      this.$refs.tableVue.tableLoading = true;
      inviteNumPeopleAPI({ page: page, limit: 30 }).then((res) => {
        if (res?.status == 1) {
          if(this.$refs.tableVue){
            this.$refs.tableVue.tableLoading = false;
          }
          this.total = parseInt(res.data.invited_count);
          this.inviteNumPeopleList = res.data.invited_list;
          this.amount = res.data.amount_count
        }
      });
    },
    changePageF(page) {
      this.page = page;
      this.getinviteNumPeopleList();
    },
  },
};
</script>

<style lang="scss" scoped>
.invitation-link {
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #2C2C2C;
    line-height: 26px;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .card {
    background: #F9F9F9;
    width: 690px;
    border-radius: 12px;
    
    color: #8A8B92;
    line-height: 22px;
    overflow: hidden;
  }
  .explain {
    display: flex;
    justify-content: space-between;
    .carousel  {
      margin-left: 12px;
      width: 218px;
      overflow: hidden;
      position: relative;
      ul {
        margin-top: -7px;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
      .item {
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        font-size: 12px;
        color: #8A8B92;
        line-height: 20px;
        width: 100%;
        text-align: center;
        padding: 4px 0;
        transition: all 0.5s ease;
      }
    }
  }
.inviteLink {
  display: flex;
  .copyBtn {
    width: 210px;
    height: 48px;
    background: #F67942;
    border-radius: 24px;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
    text-align: center;
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

  .box {
    display: flex;
    justify-content: space-between;
    height: 144px;
    font-weight: 500;
    color: #2C2C2C;
    font-size: 16px;
    line-height: 24px;
    > div {
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    .day {
      width: 400px;
      background: #FFF7F0;
      border-radius: 12px;
      padding: 26px 33px 14px;
      .value {
        color: #F67942;
      }
    }
    .month {
      width: 512px;
      background: linear-gradient( 225deg, #F67942 0%, #FFB43A 100%);
      border-radius: 12px;
      padding: 26px 24px 14px 33px;
      color: #fff;
    }
    .label {
      display: flex;
      align-items: center;
    }
    .income {
      display: flex;
      margin: 16px 0 4px;
      > * {
        margin-right: 8px;
      }
      .unit {
        margin-top: 16px;
      }
      .value {
        font-size: 36px;
        line-height: 44px;
        font-weight: 600;
      }
    }
    .last {
      font-weight: 400;
      font-size: 13px;
      color: #8A8B92;
      line-height: 18px;
    }
  }

  /* 进场的瞬间与离场的效果添加 */
  .list-enter{
    opacity: 0;
    transform: translateY(28px);
  }
  .list-leave-to {
    opacity: 0;
    transform: translateY(-28px);
  }

  /* 确保将离开的元素从布局流中删除
    以便能够正确地计算移动的动画。 */
  .list-leave-active {
    position: absolute;
    opacity: 1;
  }
}
</style>
