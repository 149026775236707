<!--
 * @FilePath: /daren-html-source/src/components/NavLeft/index.vue
 * @Description: 左侧菜单栏
-->
<template>
  <div class="NavLeft">
    <div class="navTitle">飞猫达人中心</div>
    <div
      class="item"
      v-for="item in navList"
      :key="item.id"
      :class="item.pathArr.indexOf($route.name) >= 0 ? 'act' : ''"
      @click="itemClick(item)"
    >
      <svg-btn :icon-class="'icon-menu-' + item.id" />
      {{ item.pathName }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //1、左侧取消订阅号入口、
      //2、将收益提现变成一级菜单放在左侧导航栏倒数第二位。
      //3、新的 收益提现 账号管理两个都是一级菜单只有唯一页面，所以取消二级按钮如图所示。
      navList: [
        {
          id: 0,
          pathName: this.$t('NavLeft.FEEMOO'),
          path: "/drive",
          pathArr: ["drive", "llz"],
        },
        // {
        //   id: 1,
        //   pathName: "订阅号",
        //   path: "/subscribe",
        //   pathArr: ["subscribe"],
        // },
        {
          id: 2,
          pathName: this.$t('NavLeft.PromotionCode'),
          path: "/memberRebate",
          pathArr: ["memberRebate"],
        },
        {
          id: 3,
          pathName: this.$t('NavLeft.DownlineRebate'),
          path: "/offlineRebate",
          pathArr: ["offlineRebate"],
        },
        {
          id: 4,
          pathName: this.$t('NavLeft.HelpCenter'),
          path: "/help",
          pathArr: ["help"],
        },
        {
          id: 6,
          pathName: this.$t('NavLeft.BalanceWithdrawal'),
          path: "/income",
          pathArr: ["income"],
        },
        {
          id: 5,
          pathName: this.$t('NavLeft.AccountManagement'),
          path: "/account",
          pathArr: ["account"],
        },
      ],
      routeAct: 0,
    };
  },
  methods: {
    itemClick(item) {
      if (!this.$utils.getCookie(this.$glb.fmCookieName)) {
        const from = "daren";
        const fromUrl = this.$glb.darenUrl + item.path;
        const encodedFrom = encodeURIComponent(from);
        const encodedFromUrl = encodeURIComponent(fromUrl);
        window.location.href =
          this.$glb.fmpUrl +
          `/login?from=${encodedFrom}&fromUrl=${encodedFromUrl}`;
        return;
      }
      if (item.id === 6 && localStorage.getItem("isDaren") == 0) {
        this.$toast("开通成为飞猫达人即可查看", 2);
      }
      this.$router.push(item.path);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
