<!--
 * @FilePath: /daren-html-source/src/views/Account/components/tab1.vue
 * @Description: 提现记录
-->
<template>
  <div class="tab1">
    <div class="tableHeader">
      <div
        v-for="(item, index) in tableHeader"
        :key="index"
        :class="`table${index}`"
      >
        {{ item }}
      </div>
    </div>
    <div class="tableContent" v-loading="tableLoading">
      <div class="empty" v-if="total == 0">
        <img class="emptyImg" :src="$utils.getPng('icon_empty')" alt="" />
        <div class="emptyText">{{ $t('IncomeReflect.tabOne.TextOne') }}</div>
      </div>
      <div v-else>
        <div class="item" v-for="item in tableData" :key="item">
          <div class="table0">
            {{ item.order_number }}
          </div>
          <div class="table1">¥ {{ item.money }}</div>
          <div class="table2">
            <div
              class="circle"
              :style="
                item.o_status == 'pendding'
                  ? 'background:#F67942'
                  : item.o_status == 'success'
                  ? 'background:#00B42A'
                  : 'background:#F53F3F'
              "
            ></div>
            <span>{{
              item.o_status == "pendding"
                ? $t('IncomeReflect.tabOne.awaitingProcessing')
                : item.o_status == "success"
                ? $t('IncomeReflect.tabOne.Successful')
                : $t('IncomeReflect.tabOne.Fail')
            }}</span>
            <span
              class="pointer"
              v-if="item.o_status == 'fail'"
              style="color: #f53f3f"
              @click="showRemark(item.remark)"
              >（{{ $t('IncomeReflect.tabOne.ViewCauses') }}）</span
            >
          </div>
          <div class="table3">{{ item.in_time }}</div>
        </div>
      </div>

      <div class="pagation">
        <PaginationCom :total="total" @changepage="changepage"></PaginationCom>
      </div>
      <RmarkDialog :remarkTit="remarkTit" :isShow="showRemarkDialog" @close="showRemark = false"></RmarkDialog>
    </div>
  </div>
</template>

<script>
import PaginationCom from "@/components/Pagination/index.vue";
import RmarkDialog from "@/components/ShowRemark/index.vue";
import { getIncomelistAPI } from "@/utils/apiList/user";
export default {
  components: {
    PaginationCom,
    RmarkDialog
  },
  data() {
    return {
      tableHeader: [this.$t('IncomeReflect.tabOne.OrderNumber'), this.$t('IncomeReflect.tabOne.Amount'), this.$t('IncomeReflect.tabOne.Status'), this.$t('IncomeReflect.tabOne.Time')],
      tableData: [],
      total: 0,
      tableLoading: false,
      page: 1,
      showRemarkDialog: false,
      remarkTit:''
    };
  },
  created() {
    this.getIncomelist();
  },
  methods: {
    changepage(page) {
      this.page = page;
      this.getIncomelist();
    },
    getIncomelist() {
      this.tableLoading = true;
      getIncomelistAPI({ page: this.page, limit: 20 }).then((res) => {
        if (res.status == 1) {
          this.tableLoading = false;
          this.tableData = res.data.list;
          this.total = parseInt(res.data.total);
        }
      });
    },
    showRemark(remark) {
      this.showRemarkDialog = true;
      this.remarkTit = remark;

    },
  },
};
</script>

<style lang="scss" scoped>
.tab1 {
  margin-top: 32px;
  .table0 {
    width: 240px;
    @include multiline(1);
    margin-right: 24px;
  }
  .table1 {
    width: 124px;
    margin: 0 52px 0 0;
    text-align: right;
    margin-left: 40px;
  }

  .table2 {
    min-width: 144px;
    text-align: left;
  }
  .table3 {
    width: 200px;
    text-align: left;
    margin-left: auto;
    margin-right: 0;
  }
  .tableHeader {
    width: 100%;
    height: 52px;
    background: #f9f9f9;
    border-radius: 12px;
    font-weight: 500;
    font-size: 14px;
    color: #8a8b92;
    display: flex;
    align-items: center;
    padding: 0 24px;
  }
  .tableContent {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    .empty {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 104px;
      .emptyImg {
        width: 100px;
        height: 100px;
      }
      .emptyText {
        margin-top: 8px;
        font-size: 16px;
        color: #8a8b92;
      }
    }
    .item {
      font-size: 14px;
      color: #8a8b92;
      display: flex;
      align-items: center;
      padding: 0 24px;
      height: 56px;
      box-shadow: inset 0px -1px 0px 0px #f6f6f6;
    }
    .pagation {
      margin-left: auto;
      margin-right: 0;
      margin-top: 94px;
    }
    .table2 {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #2c2c2c;
      .circle {
        width: 6px;
        height: 6px;
        background: #00b42a;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }
}
</style>
