<!--
 * @FilePath: /daren-html-source/src/views/OfflineRebate/index.vue
 * @Description: 下线返利页面
-->

<template>
  <div class="memberRebate min-height">
    <div class="mTop">
      <div
        v-for="item in topData"
        :key="item.id"
        class="mTopBtn"
        :class="{ act: topActive == item.id }"
        @click="change(item.id)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="memberContent">
      <InvitationLink v-if="topActive == 0" @change="change(1)"></InvitationLink>
      <OffIncomeData v-else></OffIncomeData>
    </div>
  </div>
</template>

<script>
import InvitationLink from './InvitationLink.vue'
import OffIncomeData from './offIncomeData.vue'

export default {
  components: {
    InvitationLink,
    OffIncomeData,
  },
  data() {
    return {
      topData: [
        { id: 0, name: this.$t('OfflineRebate.InviteRegisterLink') },
        { id: 1, name: this.$t('OfflineRebate.IncomeData') },
      ],
      topActive: 0,
    }
  },
  methods: {
    change(val){
      this.topActive = val
    }
  }
};
</script>

<style lang="scss" scoped>
.memberRebate {
  background: #f7f7f7;
  height: 100%;
  .mTopBtn {
    height: 36px;
    line-height: 36px;
    padding: 0 20px;
    border-radius: 18px;
  }
  .act {
    background: linear-gradient( 270deg, #FF9240 0%, #F67942 100%);
    font-weight: 500;
  }
  .memberContent {
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    padding: 32px 24px;
    min-height: calc(100% - 56px);
  }
}
</style>
