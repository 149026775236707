/*
 * @FilePath: /daren-html-source/src/utils/config/index.js
 * @Description:
 */

var getApiUrl = process.env.VUE_APP_API_URL;
var cdnPic = process.env.VUE_APP_CDN_URL_PIC;
var fmpUrl = process.env.VUE_APP_FM_URL;
var darenUrl = process.env.VUE_APP_DAREN_URL;
var upJmdyhUrl = process.env.VUE_APP_UPJMDYH_URL; 
var kJmdyhUrl = process.env.VUE_APP_JMDYH
var fmCookieName='feemoo_daren_token'
export default {
  getApiUrl,
  cdnPic,
  fmpUrl,
  darenUrl,
  fmCookieName,
  upJmdyhUrl,
  kJmdyhUrl
};
