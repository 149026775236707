<!--
 * @FilePath: /daren-html-source/src/views/MemberRebate/index.vue
 * @Description: 优惠码
-->

<template>
  <div class="memberRebate min-height">
    <div class="mTop">
      <div
        v-for="item in topData"
        :key="item.id"
        class="mTopBtn"
        :class="{ act: topActive == item.id }"
        size="medium"
        @click="topActive = item.id"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="memberContent">
      <RebateDisCodeVue v-if="topActive == 0"></RebateDisCodeVue>
      <IncomeDataVue v-if="topActive == 1"></IncomeDataVue>
    </div>
  </div>
</template>

<script>
import IncomeDataVue from "./incomeData.vue";
import RebateDisCodeVue from "./rebateDisCode.vue";

export default {
  components: {
    IncomeDataVue,
    RebateDisCodeVue,
  },
  data() {
    return {
      topData: [
        { id: 0, name: this.$t('MemberRebate.RebateCode') },
        { id: 1, name: this.$t('MemberRebate.IncomeData') },
      ],
      topActive: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
.memberRebate {
  background: #f7f7f7;
  .memberContent {
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    padding: 32px 24px;
    min-height: calc(100vh - 50px - 20px - 30px - 36px - 18px);
  }
}
</style>
