<!--
 * @FilePath: /daren-html-source/src/views/Help/discountCode.vue
 * @Description:  优惠码组件
-->
<template>
  <div class="discountCode">
    <div class="pub-tit0">{{ $t('help.discountCode.TextOne') }}</div>
    <div class="intrBox">
      <div class="one">
        {{ $t('help.discountCode.TextTwo') }}
      </div>
    </div>
    <div class="pub-tit0" style="margin: 52px 0 20px 0">
      {{ $t('help.discountCode.MasterRating') }}
    </div>
    <LevelTabe></LevelTabe>
  </div>
</template>

<script>
import LevelTabe from '@/components/LevelTable/index.vue'
export default {
  components: {
    LevelTabe,
  },
}
</script>

<style lang="scss" scoped>
.discountCode {
  padding-bottom: 100px;
}
</style>
