<!--
 * @FilePath: /daren-html-source/src/components/DeleteDia/index.vue
 * @Description: 删除弹框
-->
<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    custom-class="tipDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('icon-circle-fill')" />
          <span>提示</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <span> 确定删除此条收款人信息吗？ </span>
      </div>
      <div class="d-footer">
        <fm-button @click="confirm" type="primary" size="small">删除</fm-button>
        <fm-button @click="handleClose" type="info" plain size="small"
          >取 消</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "base",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    delTipsType: {
      type: Number,
      default: 1,
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    confirm() {
      this.$emit("confirm");
      this.handleClose();
    },
  },
};
</script>

<style lang="scss">
.d-content {
  margin: 34px 0 24px 0;
  text-align: center;
  font-size: 16px;
}
</style>
