<!--
 * @FilePath: /daren-html-source/src/views/Account/index.vue
 * @Description: 个人设置页面
-->

<template>
  <div class="memberRebate min-height">
    <div class="memberContent">
      <UserInfo />
    </div>
  </div>
</template>

<script>
import UserInfo from "./userInfo.vue";

export default {
  components: {
    UserInfo,
  },
  data() {
    return {
      topData: [
        { id: 0, name: "账户信息" },
        { id: 1, name: "收益提现" },
      ],
      topActive: 0,
    };
  },
  methods: {
    checkTab(id) {
      if (localStorage.getItem("isDaren") == 0 && id == 1) {
        this.$toast("开通成为飞猫达人即可查看", 2);
        return;
      }
      this.topActive = id;
    },
  },
};
</script>

<style lang="scss" scoped>
.memberRebate {
  background: #f7f7f7;
  .memberContent {
    background: #ffffff;
    border-radius: 16px 16px 0px 0px;
    padding: 32px 24px;
    min-height: calc(100vh - 50px - 20px - 30px);
  }
}
</style>
