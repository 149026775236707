<!--
 * @FilePath: /daren-html-source/src/views/OfflineRebate/offIncomeData.vue
 * @Description: 下线返利-收益数据
-->
<template>
  <div class="incomeData">
    <div class="title">
      {{$t('OfflineRebate.InvitationLink.DownLineIncome')}}
    </div>
    <div class="box">
      <div class="day">
        <div class="label">
          <svg-btn icon-class="icon4" />
          <span style="margin-left: 4px;">{{$t('OfflineRebate.offIncomeData.TodayEarnings')}}</span>
        </div>
        <div class="income">
          <span v-if="lang!=='zh'" class="unit">￥</span>
          <span class="value">{{amount.today_coupon}}</span>
          <span v-if="lang==='zh'" class="unit">元</span>
        </div>
        <img :src="$utils.getPng('back-1')" height="144" alt="">
      </div>
      <div class="day">
        <div class="label">
          <svg-btn icon-class="icon4" />
          <span style="margin-left: 4px;">{{$t('OfflineRebate.offIncomeData.YesterdayEarnings')}}</span>
        </div>
        <div class="income">
          <span v-if="lang!=='zh'" class="unit">￥</span>
          <span class="value">{{amount.yesterday_coupon}}</span>
          <span v-if="lang==='zh'" class="unit">元</span>
        </div>
        <img :src="$utils.getPng('back-1')" height="144" alt="">
      </div>
      <div class="month">
        <div class="label">
          <svg-btn icon-class="icon4" />
          <span style="margin-left: 4px;flex: auto;">{{$t('OfflineRebate.offIncomeData.CurrentEarnings')}}</span>
        </div>
        <div class="income">
          <span v-if="lang!=='zh'" class="unit">￥</span>
          <span class="value">{{amount.month_coupon}}</span>
          <span v-if="lang==='zh'" class="unit">元</span>
        </div>
        <img :src="$utils.getPng('back-2')" height="144" alt="">
      </div>
    </div>
    <TableVue
      ref="tableVue"
      :tableTitle="$t('OfflineRebate.offIncomeData.TextOne')"
      amount
      :changepage="changepage"
      :tableList="inviteNumPeopleList"
      :total="total"
    ></TableVue>
  </div>
</template>

<script>
import TableVue from "./Table.vue";
import { inviteNumPeopleAPI } from "@/utils/apiList/offlineRebate";

export default {
  components: {
    TableVue,
  },
  data() {
    return {
      total: 0,
      inviteNumPeopleList: [],
      amount:{},
      lang: ''
    };
  },
  mounted() {
    this.lang = localStorage.getItem('lang')??'zh'
    this.getinviteNumPeopleList();
  },
  methods: {
    changepage() {
    },
    async getinviteNumPeopleList(page) {
      this.$refs.tableVue.tableLoading = true;
      const res = await inviteNumPeopleAPI({ page: page, limit: 30 })
      if (res?.status == 1) {
        this.$refs.tableVue.tableLoading = false;
        this.total = parseInt(res.data.invited_count);
        this.inviteNumPeopleList = res.data.invited_list;
        this.amount = res.data.amount_count
      }
    },
    toplay() {
      this.$router.push({ path: "/help", query: { id: 2 } });
    },
  },
};
</script>

<style lang="scss" scoped>
.incomeData {
  .title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #2C2C2C;
    line-height: 26px;
    margin-bottom: 20px;
  }
  .box {
    display: flex;
    justify-content: space-between;
    height: 144px;
    font-weight: 500;
    color: #2C2C2C;
    font-size: 16px;
    line-height: 24px;
    > div {
      position: relative;
      overflow: hidden;
      img {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .day {
      width: 252px;
      background: #FFF7F0;
      border-radius: 12px;
      padding: 26px 33px 14px;
      .value {
        color: #F67942;
      }
    }
    .month {
      width: 388px;
      background: linear-gradient( 225deg, #F67942 0%, #FFB43A 100%);
      border-radius: 12px;
      padding: 26px 24px 14px 33px;
      color: #fff;
    }
    .label {
      display: flex;
      align-items: center;
    }
    .income {
      display: flex;
      margin: 16px 0 4px;
      .unit {
        margin-top: 16px;
      }
      > * {
        margin-right: 8px;
      }
      .value {
        font-size: 36px;
        line-height: 44px;
        font-weight: 600;
      }
    }
    .last {
      font-weight: 400;
      font-size: 13px;
      color: #8A8B92;
      line-height: 18px;
    }
  }
}
</style>
