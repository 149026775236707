/*
 * @FilePath: /daren-html-source/src/utils/apiList/fmp.js
 * @Description:  飞猫盘接口
 */
import request from "../api/request";
import utils from "../../utils/index";
import pfile from "@/utils/secretkey/pfile";
import sfile from "@/utils/secretkey/sfile";
var ak = utils.genak();
var ed = utils.re(ak, unescape(pfile)); //秘钥
function decrypt(data) {
    if (data?.ak) {
        let plaintext = utils.rd(data.ak, unescape(sfile));
        let obj = utils.secret(data.ed, plaintext, true);
        return JSON.parse(obj);
    } else {
        return data;
    }
}
function inParameter(data) {
    if (data) {
        return { sn: ed, jt: utils.secret(data, ak) };
    } else {
        return data;
    }
}
//下载量模式图表
export function creditDataRendAPI(data) {
    return request({
        url: "/cash/creditDataRend",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//达人模式图表
export function creditDataDarenRendAPI(data) {
    return request({
        url: "/cash/creditDataDarenRend",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}
//兑换
export function cloudToTaskAPI(data) {
    return request({
        url: "/cash/cloudToTask",
        method: "post",
        data: inParameter(data),
    })
        .then((res) => {
            res.data = decrypt(res.data);
            return res;
        })
        .catch((err) => {
            console.log(err);
        });
}