<!--
 * @FilePath: /daren-html-source/src/views/MemberRebate/rebateDisCode.vue
 * @Description:  返利优惠码
-->
<template>
  <div class="rebateDisCode">
    <div
      class="levelbox"
      :style="`backgroundImage:url(${$utils.getPng('discodebg0')})`"
    >
      <img
        class="levelimg"
        :src="$store.state.userInfo.user_plan.icon"
        alt=""
      />
      <div class="info">
        <div class="infotop">
          {{ $t('MemberRebate.rebateDisCode.YourCurrentLevel') }}<span
            class="levelAct"
            :style="`color:#${$utils.getLevelColor(
              $store.state.userInfo.user_plan.subject
            )}`"
            >{{ $store.state.userInfo.user_plan.subject }}</span
          >
        </div>

        <fm-button
          class="infobtn"
          type="primary"
          plain
          size="mini"
          @click="toplay"
          >{{ $t('MemberRebate.rebateDisCode.Levels') }}</fm-button
        >
      </div>
      <div class="equityBox">
        <div class="discountBox">
          <div class="disNum">
            {{ $store.state.userInfo.user_plan.user_coupon_discount_rate }}
          </div>
          <div class="disIntr">{{ $t('MemberRebate.rebateDisCode.CouponCodeDiscount') }}</div>
        </div>
        <div class="line"></div>
        <div class="discountBox">
          <div class="disNum">
            {{ $store.state.userInfo.user_plan.user_coupon_rebate_rate }}
          </div>
          <div class="disIntr">{{ $t('MemberRebate.rebateDisCode.RebateRatio') }}</div>
        </div>
      </div>
    </div>
    <div
      class="myDisCodeBox"
      :style="`backgroundImage:url(${$utils.getPng('discodebg1')})`"
    >
      <div class="leftBox">
        <img class="icon1" :src="$utils.getPng('icon1')" alt="" />
        <div class="myDisIntr">{{ $t('MemberRebate.rebateDisCode.MyPromoCode') }}</div>
      </div>
      <div class="rightBox">
        <div class="disCode1" :class="inviteData.is_old_code == 1 ? 'oldCode' : ''">
          {{ inviteData.qrcode }}
        </div>
        <div class="disbtn" @click="copyBtn(inviteData.qrcode)">{{ $t('MemberRebate.rebateDisCode.Copy') }}</div>
      </div>
      <div class="shadowBox"></div>
    </div>
    <MemRbCom :title="$t('MemberRebate.rebateDisCode.MemberRebateIncome')" :memberList="memberList"></MemRbCom>
  </div>
</template>

<script>
import MemRbCom from "@/components/MemRbCom/index.vue";
import {
  invitedDiscountCodeAPI,
  invitedGradeAPI,
} from "@/utils/apiList/offlineRebate";
export default {
  components: {
    MemRbCom,
  },
  data() {
    return {
      inviteData: [],
      memberList: [],
    };
  },
  created() {
    this.invite();
    this.invitedGrade();
  },
  methods: {
    invite() {
      invitedDiscountCodeAPI().then((res) => {
        if (res.status == 1) {
          this.inviteData = res.data.invited;
        }
      });
    },

    copyBtn(url) {
      this.$copyText(url).then(
        (e) => {
          this.$toast("复制成功", 1);
        },
        function (e) {
          this.$toast("复制失败", 2);
        }
      );
    },
    toplay() {
      this.$router.push({ path: "/help", query: { id: 1 } });
    },
    invitedGrade() {
      //获取今日昨日
      invitedGradeAPI().then((res) => {
        if (res.status == 1) {
          this.memberList = res.data.coupon;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rebateDisCode {
  padding-bottom: 100px;
  .levelbox {
    width: 100%;
    height: 128px;
    background-size: 100%;
    display: flex;
    padding: 32px 48px 32px 42px;
    .levelimg {
      width: 64px;
      height: 64px;
      margin-right: 12px;
    }
    .info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .infotop {
        height: 24px;
        font-weight: 400;
        font-size: 16px;
        color: #8a8b92;
        line-height: 24px;
        .levelAct {
          color: #f28c2a;
        }
      }
      .infobtn {
        width: 72px;
        height: 28px;
        border-radius: 18px;
        font-weight: 500;
        font-size: 12px;
      }
    }
    .equityBox {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 0;
      .line {
        width: 1px;
        height: 40px;
        background: #e5e5e5;
        border-radius: 1px;
        margin: 0 36px 0 32px;
      }

      .discountBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        .disNum {
          height: 40px;
          font-weight: 500;
          font-size: 32px;
          color: #2c2c2c;
          line-height: 40px;
        }

        .disIntr {
          height: 22px;
          font-weight: 400;
          font-size: 14px;
          color: #8a8b92;
          line-height: 22px;
          margin-top: 2px;
        }
      }
    }
  }

  .myDisCodeBox {
    background-size: 100%;
    background-repeat: no-repeat;
    height: 193px;
    margin-top: 32px;
    display: flex;
    padding: 0 68px;
    position: relative;

    .leftBox {
      display: flex;
      align-items: center;
      .icon1 {
        width: 54px;
        height: 54px;
        margin-right: 12px;
      }
      .myDisIntr {
        height: 26px;
        font-weight: 500;
        font-size: 18px;
        color: #2c2c2c;
        line-height: 26px;
        flex-shrink: 0;
      }
    }
    .rightBox {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 0;
      .disCode1 {
        height: 64px;
        font-weight: 600;
        font-weight: 500;
        font-size: 50px;
        color: #f67942;
        line-height: 64px;
        letter-spacing: 6px;
        text-align: center;
      }
      .oldCode {
        font-size: 48px !important;
        color: #f67942;
        letter-spacing: 2px !important;
      }
      .disbtn {
        width: 76px;
        height: 32px;
        background: linear-gradient(164deg, #ff9e41 0%, #ff7031 100%);
        box-shadow: 0px 10px 14px -1px rgba(246, 122, 66, 0.2);
        border-radius: 18px;
        font-size: 14px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;
        cursor: pointer;
      }
    }
    .shadowBox {
      position: absolute;
      bottom: 2px;
      left: 20px;
      width: calc(980px - 40px - 48px);
      height: 68px;
      box-shadow: 0px 20px 24px 0px rgba(222, 222, 222, 0.3);
      // background: pink;
      border-radius: 18px;
    }
  }
}
</style>
