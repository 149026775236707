<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    :before-close="handleClose"
    custom-class="bindemailDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <img :src="$utils.getPng('icon-circle-fill')" />
          <span>{{ type == 2 ? "实名认证" : "绑定邮箱" }}</span>
        </div>
        <fm-button
          type="info"
          size="mini"
          text
          icon="icon-model-close"
          @click="handleClose"
        ></fm-button>
      </div>
      <div class="d-content">
        <span class="tips" v-if="type != 3">
          {{
            type == 1
              ? "请输入需要新绑定的邮箱"
              : "请完成实名认证后再继续操作"
          }}
        </span>
        <span class="tips2" v-else
          >请输入发送至 <font class="email">{{ email }}</font> 的
          <font class="num">6</font> 位验证码有效期3分钟</span
        >
        <div class="dc1" v-show="type == 1">
          <el-input
            v-model="email"
            placeholder="请输入新邮箱"
            clearable
          >
          </el-input>
        </div>
        <div class="dc2" v-show="type == 2">
          <el-input v-model="name" placeholder="请输入真实姓名" clearable>
          </el-input>
          <el-input
            v-model="account"
            placeholder="请输入18位身份证号"
            clearable
          ></el-input>
        </div>
        <div class="dc3" v-show="type == 3">
          <div class="verifcode-box">
            <VerificationCode
              ref="verificationCode"
              :error="reload"
              :i-loading="loading"
              @next="next"
              @changeload="changeload"
            ></VerificationCode>
          </div>
          <span class="countdown" v-if="time == 60" @click="reGetCode()">
            重新获取验证码
          </span>
          <div class="countdown" v-else>{{ time }}s后重新获取</div>
        </div>
      </div>

      <div class="d-footer">
        <fm-button
          @click="getCode()"
          type="primary"
          size="small"
          v-show="type == 1"
          :disabled="email.length < 1"
          >下一步</fm-button
        >
        <fm-button
          @click="verifFlag ? '' : binding()"
          type="primary"
          size="small"
          v-show="type == 3"
          :disabled="verifFlag"
          >确 定</fm-button
        >
        <fm-button
          @click="name == '' || account == '' ? '' : confirm()"
          type="primary"
          size="small"
          v-show="type == 2"
          :disabled="name == '' || account == ''"
          >确 定</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
import VerificationCode from "./VerificationCode.vue";
export default {
  components: {
    VerificationCode,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default: 1,
    },
    time: {
      type: Number,
      default: 60,
    },
  },
  data() {
    return {
      email: "",
      pcode: "+86",
      loading: "",
      reload: "",
      verifCode: "", //验证码
      verifFlag: true,
      name: "",
      account: "",
    };
  },
  watch: {
    isShow(newVal) {
      if (newVal == true) {
        if (!document.getElementById("aliyunCaptcha-window-popup")) {
          this.$AliCode.initAliCode();
        }
      }
    },
  },
  methods: {
    handleClose() {
      this.email = "";
      (this.name = ""), (this.account = "");
      this.$emit("handleClose");
    },
    //获取验证码
    getCode() {
      this.$emit("getemailCode", this.email, this.pcode);
    },
    //获取验证码之后的验证码
    next(val) {
      const str = val && val.toString().replace(/,/g, "");
      this.verifCode = str;
      this.nextApi(str);
    },
    nextApi(str) {
      let params = {
        pcode: this.pcode,
        email: this.email,
        ucode: str,
      };
      this.$emit("bindemail", params);
    },
    changeload(val) {
      // this.verifCode = val;
      this.verifFlag = val;
    },
    binding() {
      if (this.verifCode.length < 6) {
        return;
      }
      this.nextApi(this.verifCode);
    },
    reGetCode() {
      this.$refs.verificationCode.reset(), this.getCode();
    },
    confirm() {
      let data = {
        name: this.name,
        account: this.account,
      };
      this.$emit("confirmNameAuth", data);
    },
    onFocus() {
      this.$refs.verificationCode.resetCaret();
    },
  },
};
</script>
<style lang="scss">
.bindemailDialog {
  .d-content {
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    color: #2c2c2c;
    margin: 20px 0 0 0;
    .tips {
      width: 100%;
      display: inline-block;
      line-height: 22px;
      margin-bottom: 16px;
      text-align: left;
    }
    .tips2 {
      display: inline-block;
      line-height: 22px;
      margin-bottom: 16px;

      color: #8a8b92;
      .email {
        color: #2c2c2c;
      }
      .num {
        color: #f67942;
      }
    }
    .dc1 {
      margin-bottom: 24px;
      .el-input {
        display: flex;
        align-items: center;
        width: 432px;
        height: 39px;
        line-height: 39px;
        .el-input__inner {
          height: 39px;
          line-height: 39px;
        }
        .el-input-group__prepend {
          border: none;
          box-shadow: none;
          background: transparent;
          position: absolute;
          padding: 0 16px 0 12px;
          line-height: 39px;
          height: 39px;
          color: #2c2c2c;
        }
      }
    }
    .dc2 {
      margin-bottom: 12px;
      .el-input {
        margin-bottom: 12px;
      }
    }
    .dc3 {
      margin-bottom: 12px;
      .countdown {
        cursor: pointer;
        display: inline-block;
        font-size: 12px;
        color: #f67942;
        line-height: 20px;
        margin-top: 12px;
      }
    }
  }
}
</style>
