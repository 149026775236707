<!--
 * @FilePath: /daren-html-source/src/components/AddPayee/index.vue
 * @Description: 新增收款人弹窗
-->
<template>
  <el-dialog
    :visible.sync="isShow"
    custom-class="titleDialog"
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left">
          <img
            class="icon"
            :src="$utils.getPng('icon-circle-fill')"
            alt=""
            srcset=""
          />
          <span>设置收款人信息</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content">
        <div class="item autoHeght">
          <div class="left autoHeght"></div>
          <div class="right">
            <div class="oneFont">请完善收款人信息，用于飞猫达人收益提现</div>
          </div>
        </div>
        <div class="item">
          <div class="left">姓名<span class="red">*</span></div>
          <div class="right inputr">
            <el-input
              style="width: 350px"
              v-model="form.aliname"
              placeholder="请输入证件姓名"
            >
            </el-input>
            <div class="intr">
              姓名必须与该支付宝登记的姓名一致，否则无法提现。
            </div>
          </div>
        </div>
        <div class="item">
          <div class="left">身份证号<span class="red">*</span></div>
          <div class="right">
            <el-input
              style="width: 350px"
              v-model="form.account"
              placeholder="请输入身份证号"
            >
            </el-input>
          </div>
        </div>
        <div class="item autoHeght">
          <div class="left">支付宝账号<span class="red">*</span></div>
          <div class="right">
            <el-input
              style="width: 350px"
              v-model="form.aliacc"
              placeholder="请输入支付宝账号"
            >
            </el-input>
          </div>
        </div>
        <!-- <div
          class="item"
          style="height: 52px"
          v-if="$store.state.userInfo.phone"
        >
          <div class="left" style="height: 24px; line-height: 24px">手机号</div>
          <div class="right">
            <div class="phoneBox" style="height: 24px; line-height: 24px">
              {{ $utils.hidePhone(form.bindPhone) }}
            </div>
          </div>
        </div>
        <div class="item" v-else>
          <div class="left">手机号</div>
          <div class="right pcodeBox">
            <div class="pcode">+86</div>
            <el-input
              style="width: 320px"
              v-model="form.bindPhone"
              :maxlength="11"
              placeholder="请输入手机号"
            >
            </el-input>
          </div>
        </div>
        <div class="item" style="height: 40px">
          <div class="left">输入验证码</div>
          <div class="right">
            <div class="sendBox">
              <el-input
                style="width: 320px"
                v-model="form.qcode"
                placeholder="请输入验证码"
              >
              </el-input>
              <div
                class="btnSend"
                :style="time != '60' ? 'cursor: no-drop;' : ''"
                @click="time == '60' ? toSendcode() : ''"
              >
                {{ time == "60" ? "获取验证码" : "已发送(" + time + "s)" }}
              </div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="d-footer">
        <fm-button
          :disabled="disabled"
          @click="confirm"
          type="primary"
          size="small"
          >确 认</fm-button
        >
        <!-- <fm-button @click="handleClose" type="info" plain size="small"
          >取 消</fm-button
        > -->
      </div>
    </div>
  </el-dialog>
</template>

<script>
import {
  updatePayeeAPI,
  sendCodeAPI,
  updatePayeeAPINew,
} from '@/utils/apiList/user'

export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        aliname: '',
        account: '',
        aliacc: '',
        qcode: '',
        smsid: '',
        editId: '0',
        isEdit: '0',
        bindPhone: this.$store.state.userInfo.phone,
      },
      time: 60,
      timerA: '',
    }
  },
  computed: {
    disabled() {
      return (
        this.form.aliname.length == 0 ||
        this.form.account.length == 0 ||
        this.form.aliacc.length == 0
      )
    },
  },
  beforeDestroy() {
    this.time = 60
    this.timerA && clearInterval(this.timerA)
  },
  methods: {
    handleClose() {
      this.$parent.showAddpayFlag = false
      this.time = 60
      this.timerA && clearInterval(this.timerA)
      this.$emit('handleClose')
      this.form = {
        aliname: '',
        account: '',
        aliacc: '',
        qcode: '',
        smsid: '',
        editId: 0,
        isEdit: 0,
        bindPhone: this.$store.state.userInfo.phone,
      }
    },
    minute: function () {
      this.time = this.time - 1
      if (this.time == 0) {
        this.time = 60
        clearInterval(this.timerA)
      }
    },
    toSendcode: _.debounce(
      function (captcha_verify) {
        return new Promise(resolve => {
          if (this.time != 60) {
            return
          }
          var reg_tel =
            /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
          if (!reg_tel.test(this.form.bindPhone)) {
            this.$toast('请正确输入手机号', 3)
            return true
          }
          this.timerA && clearInterval(this.timerA)

          sendCodeAPI({
            pcode: '+86',
            phone: this.form.bindPhone,
            captcha_verify: captcha_verify || '',
          })
            .then(res => {
              if (res.status == 1) {
                resolve({
                  result: true,
                  validate: true,
                })
                this.form.smsid = res.data.msgid
                this.timerA = setInterval(() => {
                  this.minute()
                }, 1000)
                this.$toast(res.msg, 1)

                this.$toast(res.msg, 1)
              } else if (res.status == 90001) {
                resolve({
                  result: false,
                  validate: false,
                })
                this.$toast(res.msg, 2)
                this.$AliCode.handleOpen(this.toSendcode)
              } else {
                resolve({
                  result: true,
                  validate: true,
                })
                this.$toast(res.msg, 2)
              }
            })
            .catch(err => {})
        })
      },
      1000,
      {
        leading: true, //指定在延迟开始前调用
        trailing: false, //指定在延迟结束后调用
      }
    ),

    confirm() {
      if (this.disabled) {
        return
      }
      if (!this.form.aliname) {
        this.$toast('请填写姓名', 2)
        return
      }

      if (!this.form.account) {
        this.$toast('请填写身份证', 2)
        return
      }
      if (!this.form.aliacc) {
        this.$toast('请填写支付宝账号', 2)
        return
      }

      // if (!this.form.qcode) {
      //   this.$toast('请填写验证码', 2)
      //   return
      // }

      let params = this.form

      updatePayeeAPINew(params)
        .then(res => {
          if (res.status == 1) {
            this.$toast(res.msg, 1)
            if (
              this.$route.fullPath == '/llz' ||
              this.$route.fullPath == '/en-US/llz'
            ) {
              this.$parent.userInfo.is_income_account = 1
              this.$parent.openDaren()
            } else {
              this.$parent.getPayeeList()
            }
            this.handleClose()
          } else {
            this.$toast(res.msg, 2)
          }
        })
        .catch(err => {})
    },
    resetTime() {
      this.time = 60
      this.timerA && clearInterval(this.timerA)
    },
  },
}
</script>

<style lang="scss">
.el-dialog {
  border-radius: 16px;
  color: #fff;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    // box-shadow: 0px 6px 30px 5px rgba(0, 0, 0, 0.05), 0px 16px 24px 2px rgba(0, 0, 0, 0.04), 0px 8px 10px -5px rgba(0, 0, 0, 0.08);
    padding: 20px 24px 24px 24px;
    border-radius: 16px;
    color: #2c2c2c;
  }
  .d-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
    .fm-button {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .d-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      &:last-child {
        margin-left: 12px;
      }
    }
  }
}
.titleDialog {
  width: 560px;
  // height: 452px;
  .d-header {
    .left {
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
    }
  }
  .d-content {
    margin: 32px 0 24px 0;
    .autoHeght {
      height: auto !important;
    }
    .oneFont {
      font-weight: 400;
      font-size: 14px;
      color: #2c2c2c;
      line-height: 22px;
      margin-bottom: 24px;
    }
    .title {
      font-size: 16px; //设计图有问题18 ——> 16
      line-height: 24px;
      text-align: center;
    }
    .txt {
      margin-top: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
      span {
        line-height: 22px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.item {
  display: flex;
  align-items: flex-start;
  height: 68px;
  font-weight: 400;
  font-size: 14px;
  color: #2c2c2c;
  .red {
    font-weight: 400;
    font-size: 14px;
    color: #f53f3f;
    line-height: 22px;
  }
  .left {
    height: 40px;
    line-height: 40px;
    // width: 86px;
    width: 105px;
    text-align: right;
    flex-shrink: 0;
  }
  .right {
    margin-left: 20px;
    // width: 320px;

    .sendBox {
      display: flex;
      height: 40px;
      align-items: center;
      .btnSend {
        font-weight: 500;
        font-size: 12px;
        color: #f67942;
        line-height: 20px;
        margin-left: 16px;
        cursor: pointer;
      }
    }
    .intr {
      height: 20px;
      font-weight: 400;
      font-size: 12px;
      color: #bdbdbe;
      line-height: 20px;
      text-align: left;
    }
    .phoneBox {
      height: 40px;
      line-height: 40px;
    }
  }
}
.pcodeBox {
  position: relative;
  display: flex;
  .pcode {
    position: absolute;
    top: 50%;
    left: 12px;
    font-weight: 400;
    font-size: 14px;
    z-index: 100;
    transform: translateY(-50%);
  }
  ::v-deep .el-input .el-input__inner {
    padding-left: 54px;
  }
}
</style>
