<!--
 * @FilePath: /daren-html-source/src/views/Help/rebate.vue
 * @Description: 下线返利tab
-->
<template>
  <div class="rebate">
    <div class="pub-tit0">{{ $t('help.rebate.TextOne') }}</div>
    <div class="intrBox">
      <div class="one">{{ $t('help.rebate.TextTwo') }}</div>
      <div class="one">
        {{ $t('help.rebate.TextThree') }}
      </div>

    </div>
    <div class="pub-tit0" style="margin: 52px 0 20px 0">{{ $t('help.rebate.MasterRating') }}</div>
    <LevelTabe></LevelTabe>
  </div>
</template>

<script>
import LevelTabe from "@/components/LevelTable/index.vue";
export default {
  components: {
    LevelTabe,
  },
};
</script>

<style lang="scss" scoped>
.rebate {
  padding-bottom: 100px;
}
</style>
