<!--
 * @FilePath: /daren-html-source/src/components/OpenDarenDia/step2.vue
 * @Description: 开通达人挽留 弹窗2
-->
<template>
  <el-dialog
    title="提示"
    :visible.sync="isShow"
    width="480px"
    custom-class=""
    :append-to-body="true"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div class="d-header">
        <div class="left" style="opacity: 0;">
          <img :src="$utils.getPng('icon-circle-fill')" />
          <span>提示</span>
        </div>
        <fm-button
          @click="handleClose"
          type="info"
          size="mini"
          text
          icon="icon-model-close"
        ></fm-button>
      </div>
      <div class="d-content" style="margin: 24px 0 ;">
        <p class="title">确定现在不开通飞猫达人吗？</p>
        <div class="txt">
          <span>
            现在开通飞猫达人即可享受平台帮扶，推荐账号的展示次数，先开先得哦！
          </span>
        </div>
      </div>
      <div class="d-footer">
        <fm-button @click="confirm" type="primary" size="small"
          >飞猫达人开通认证</fm-button
        >
        <fm-button @click="handleClose" type="info" plain size="small"
          >暂不开通</fm-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "title",
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    cancelTips: {
      type: String,
      default: "",
    },
    deleteHJ: {
      type: String,
      default: "",
    },
  },
  methods: {
    handleClose() {
      this.$emit("handleClose");
    },
    confirm() {
      this.$emit("confirm");
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 16px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #2c2c2c;
}
.txt {
  font-size: 14px;
  width: 100%;
  text-align: left;
}
</style>
